import React from "react";
import { Redirect } from "react-router-dom";

import Posts from "../pages/Posts";
import HandlePost from "../pages/Posts/Create";
import EditPost from "../pages/Posts/Edit";

import Videos from "../pages/Videos";
import HandleVideo from "../pages/Videos/Create";
import EditVideo from "../pages/Videos/Edit";

import Tags from "../pages/Tags";
import Menus from "../pages/Menus";
import Configs from "../pages/Configs";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Users from "../pages/Authentication/Users";
import Roles from "../pages/Authentication/Role";
import Permisstions from "../pages/Authentication/RoleAction";
import Categories from "../pages/Categories";

const authProtectedRoutes = [
  { path: "/posts", component: Posts },
  { path: "/post/:id", component: EditPost },
  { path: "/post", component: HandlePost },
  { path: "/videos", component: Videos },
  { path: "/video/:id", component: EditVideo },
  { path: "/video", component: HandleVideo },
  { path: "/tags", component: Tags },
  { path: "/menus", component: Menus },
  { path: "/configs", component: Configs },
  { path: "/categories", component: Categories },
  { path: "/users", component: Users },
  { path: "/roles", component: Roles },
  { path: "/permisstions", component: Permisstions },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
